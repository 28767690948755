require('../require');
require('/node_modules/chart.js/dist/Chart.js');
require('./page.css');

import { siOnEstEnPeriodeHivernale } from '../../js/services/passage-hiver';

if (siOnEstEnPeriodeHivernale()) {
  // Affiche l'encart quand on est dans l'interval de l'hiver
  document.querySelector('#encartHiver').hidden = false;
} else {
  // Enlève l'encart quand on est en dehors de l'interval de l'hiver
  document.querySelector('#encartHiver').hidden = true;
}


